<script lang="ts" setup>
import type { IEvent } from "@/types";
import { EVENT_STATUS } from "@/constants";

export interface ScoreboardProps {
  event: IEvent;
}

const { t, locale } = useI18n();
const props = defineProps<ScoreboardProps>();
const formatDate = (date: Date | number, format: string) =>
  useDateFormat(new Date(date || ""), format, { locales: locale.value }).value;

const formatScore = (score: number) => score !== null && score !== undefined ? score : "—";
</script>

<template>
  <div
    class="scoreboard grid grid-cols-[1fr_10.313rem_1fr] gap-4 p-4 pt-12 md:grid-cols-[1fr_14.375rem_1fr] md:px-3 lg:grid-cols-[1fr_18.75rem_1fr] lg:py-4 xl:pt-5"
  >
    <NuxtLink :to="{ path: `/teams/${props.event.game_info.team1_id}` }">
      <div
        class="relative right-10 -mx-5 flex translate-x-1/2 flex-col items-center gap-2 md:right-14 xl:right-0 xl:mx-0 xl:translate-x-0 xl:flex-row-reverse xl:gap-4 rtl:left-10 rtl:right-auto rtl:-translate-x-1/2 rtl:md:left-14 rtl:xl:left-0 rtl:xl:translate-x-0"
      >
        <UAvatar
          :src="props.event.game_info.team1_logo_url"
          :alt="props.event.game_info.team1_name"
          :size="88"
        />
        <h3
          class="text-center font-medium dark:text-white max-[93.75rem]:text-[1.25rem] max-[93.75rem]:leading-6 max-[48rem]:text-base xl:text-right min-[93.75rem]:text-2xl rtl:xl:text-left"
        >
          {{ props.event.game_info.team1_name }}
        </h3>
        <UIcon
          v-if="props.event.permissions.canEdit && event.permissions.canAddHomeGameInfo"
          v-tooltip="t('pages.events.scoreboard_team_unofficial_icon_tooltip')"
          name="warning"
          class="inline-block size-4 shrink-0 text-orange-500 hover:text-orange-600"
        />
      </div>
    </NuxtLink>
    <div
      class=":pt-2 center flex flex-wrap justify-between gap-2 self-start md:flex-nowrap md:justify-center md:gap-4 md:pt-4"
    >
      <div class="home row-start-[left] row-end-[left] flex shrink-0 items-center gap-2 md:flex-1">
        <div
          v-if="event.status === EVENT_STATUS.VOD"
          class="ps-3 text-2xl font-bold dark:text-white md:ps-0 md:text-4xl lg:text-[2.5rem] xl:text-5xl"
        >
          {{ formatScore(props.event.game_info.team1_score) }}
        </div>
        <div
          v-if="props.event.game_info.team1_score > props.event.game_info.team2_score"
          class="border-y-[0.375rem] border-solid border-y-transparent ltr:border-l-0 ltr:border-r-[0.563rem] ltr:border-r-red-500 rtl:border-l-[0.563rem] rtl:border-r-0 rtl:border-l-red-500"
        />
      </div>
      <div
        class="relative order-first col-span-3 row-start-[top] row-end-[top] flex w-full flex-row place-content-center text-center text-xs font-medium text-neutral-light-800 dark:text-neutral-dark-200 md:order-none md:w-auto md:flex-col md:text-sm lg:w-40"
      >
        <p>{{ formatDate(props.event.start_date, "hh:mm A") }}</p>
        <p class="ml-2 capitalize md:ml-0">
          {{ formatDate(props.event.start_date, "MMMM DD, YYYY") }}
        </p>
      </div>
      <div
        class="relative row-start-[centre] row-end-[centre] flex items-center justify-center text-5xl after:absolute after:left-1/2 after:top-1/2 after:h-0.5 after:w-2.5 after:-translate-x-1/2 after:-translate-y-1/2 after:bg-black after:content-[''] dark:after:bg-white md:hidden"
        :class="props.event.game_info.team1_score > props.event.game_info.team2_score ? '-ml-4' : '-mr-4'"
      />
      <div class="row-start-[right] row-end-[right] flex shrink-0 items-center justify-end gap-2 md:flex-1">
        <div
          v-if="props.event.game_info.team2_score > props.event.game_info.team1_score"
          class="border-y-[0.375rem] border-solid border-y-transparent ltr:border-l-[0.563rem] ltr:border-r-0 ltr:border-l-red-500 rtl:border-l-0 rtl:border-r-[0.563rem] rtl:border-r-red-500"
        />
        <div
          v-if="event.status === EVENT_STATUS.VOD"
          class="pe-3 text-2xl font-bold dark:text-white md:pe-0 md:text-4xl lg:text-[2.5rem] xl:text-5xl"
        >
          {{ formatScore(props.event.game_info.team2_score) }}
        </div>
      </div>
    </div>
    <NuxtLink :to="{ path: `/teams/${props.event.game_info.team2_id}` }">
      <div
        class="relative left-10 -mx-5 flex -translate-x-1/2 flex-col items-center gap-2 md:left-14 xl:left-0 xl:mx-0 xl:translate-x-0 xl:flex-row xl:gap-4 rtl:left-auto rtl:right-10 rtl:translate-x-1/2 rtl:md:right-14 rtl:xl:right-0 rtl:xl:translate-x-0"
      >
        <UAvatar
          :src="props.event.game_info.team2_logo_url"
          :alt="props.event.game_info.team2_name"
          :size="88"
        />
        <h3
          class="text-center font-medium dark:text-white max-[93.75rem]:text-[1.25rem] max-[93.75rem]:leading-6 max-[48rem]:text-base xl:text-left min-[93.75rem]:text-2xl rtl:xl:text-right"
        >
          {{ props.event.game_info.team2_name }}
        </h3>
        <UIcon
          v-if="props.event.permissions.canEdit && event.permissions.canAddAwayGameInfo"
          v-tooltip="t('pages.events.scoreboard_team_unofficial_icon_tooltip')"
          name="warning"
          class="inline-block size-4 shrink-0 text-orange-500 hover:text-orange-600"
        />
      </div>
    </NuxtLink>
  </div>
</template>
