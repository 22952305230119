<script lang="ts" setup>
const { t } = useI18n();
const emit = defineEmits(["close"]);

function onClose() {
  emit("close");
}
</script>

<template>
  <UModal
    appear
    :model-value="true"
  >
    <div class="flex items-center justify-end p-4 pb-3">
      <UButton
        title="Close"
        variant="link-secondary"
        icon="close"
        class="size-5"
        @click="onClose"
      />
    </div>

    <div class="md:px-10 md:pb-10">
      <div class="flex flex-col">
        <div class="flex flex-col items-center justify-center pb-4">
          <UIcon
            name="send-for-breakdown"
            class="flex fill-dark-blue-500 stroke-dark-blue-500 text-[64px] dark:fill-white dark:stroke-white"
          />
        </div>
        <div class="flex pb-4 text-center text-2xl font-bold">
          {{ t("pages.breakdowns.not_enough_balance_modal.title") }}
        </div>
        <div class="flex pb-4 text-center text-sm font-medium">
          {{ t("pages.breakdowns.not_enough_balance_modal.description") }}
        </div>
        <div class="flex justify-center">
          <UButton
            size="lg"
            class="w-48"
            truncate
            :label="t('labels.okay')"
            @click="onClose"
          />
        </div>
      </div>
    </div>
  </UModal>
</template>
