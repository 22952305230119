import type { TextOptionsLight, jsPDF } from "jspdf";
import type { RowInput, PageHook, UserOptions, CellHook } from "jspdf-autotable";
import autoTable from "jspdf-autotable";
import addFonts from "./fonts";

export async function createFirstPage(
  doc: jsPDF,
  opts: {
    title?: string;
    description?: string;
    date?: string;
    coverBackground?: string;
  },
): Promise<void> {
  const defaultBackground = "/images/pdf/report-bg.png";
  const bg = opts.coverBackground || defaultBackground;
  try {
    doc.addImage(bg, "png", 0, 0, 595, 842);
  }
  catch {
    doc.addImage(defaultBackground, "png", 0, 0, 595, 842);
  }
  doc.addImage("/images/pdf/logo.png", "png", 498, 26, 77, 12);

  addFonts(doc);

  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

  const page = {
    padding: { x: 33, y: 73 },
    rowWidth: pageWidth - 66,
  };
  const textOptions: TextOptionsLight = {
    maxWidth: page.rowWidth,
  };
  let dateY = 174;

  if (opts.title) {
    doc.setFont("RedHatDisplay-Bold", "normal", "bold");
    doc.setFontSize(32);
    doc.setTextColor(255, 255, 255); // Set white color
    doc.text(opts.title.toUpperCase(), page.padding.x, page.padding.y, textOptions);
  }

  if (opts.description) {
    doc.setFontSize(14);
    // TODO: Render 'Y' position based on the last drawn text element
    doc.text(opts.description, page.padding.x, 147, textOptions);

    const lines = doc.splitTextToSize(opts.description, page.rowWidth);

    if (lines.length > 1) {
      dateY += doc.getLineHeight() * (lines.length - 1);
    }
  }

  if (opts.date) {
    doc.setFont("RedHatDisplay-Medium", "normal");
    doc.setFontSize(12);
    doc.text(opts.date, page.padding.x, dateY);
  }
}

export async function createPageHeader(doc: jsPDF, title?: string) {
  doc.addImage("/images/pdf/top.png", "png", 0, 0, 595, 65);
  doc.addImage("/images/pdf/logo.png", "png", 498, 26, 77, 12);

  if (title) {
    doc.setFont("RedHatDisplay-Bold", "normal", "bold");
    doc.setFontSize(18);
    doc.setTextColor(255, 255, 255);
    doc.text(title.toUpperCase(), 20, 40);
  }
}
export function createPageFooter(doc: jsPDF) {
  // @ts-expect-error `getNumberOfPages` should exist and return current page number
  const pageNumber = doc.internal.getNumberOfPages();

  const pageSize = doc.internal.pageSize;
  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

  doc.setFont("RedHatDisplay-Medium", "normal");
  doc.setFontSize(10);
  doc.setTextColor("#8F959F");
  doc.text(`${pageNumber}`, pageWidth - 20, pageHeight - 20, { align: "right" });
}

export function createSimpleTable(
  doc: jsPDF,
  options: {
    marginTop?: number;
    pageHeading?: string;
    startY?: number;
    head?: RowInput[];
    body?: RowInput[];
    willDrawPage?: PageHook;
    didDrawPage?: PageHook;
    willDrawCell?: CellHook;
    didDrawCell?: CellHook;
    additionalOptions?: Partial<UserOptions>;
  },
) {
  autoTable(doc, {
    ...options?.additionalOptions,

    startY: options.startY,
    tableWidth: "auto",
    margin: {
      top: options.marginTop || 0,
      bottom: 60,
      left: 20,
      right: 20,
    },
    head: options.head,
    headStyles: {
      font: "RedHatDisplay-Bold",
      fontStyle: "bold",
      halign: "center",
      fillColor: "#EEEEEE",
      textColor: "#000000",
      fontSize: 10,
      ...options?.additionalOptions?.headStyles,
    },
    alternateRowStyles: {
      font: "RedHatDisplay-Medium",
      fontStyle: "normal",
      fontSize: 10,
      fillColor: "#FFFFFF",
      textColor: "#1A1C1E",
      halign: "center",
      valign: "middle",
      ...options?.additionalOptions?.alternateRowStyles,
    },
    bodyStyles: {
      font: "RedHatDisplay-Medium",
      fontStyle: "normal",
      fontSize: 10,
      fillColor: "#F8F8F8",
      textColor: "#1A1C1E",
      halign: "center",
      valign: "middle",
      ...options?.additionalOptions?.bodyStyles,
    },
    styles: {
      cellWidth: "wrap",
    },
    body: options.body,
    willDrawPage: (hookData) => {
      createPageHeader(doc, options.pageHeading);

      createPageFooter(doc);

      if (options.willDrawPage) {
        options.willDrawPage(hookData);
      }
    },
    didDrawPage: options.didDrawPage,
    didParseCell: function (hookData) {
      // @ts-expect-error Check if the cell contains an image
      const content = hookData.cell.raw?.content;
      if (typeof content === "string" && content.startsWith("data:image")) {
        // @ts-expect-error Saving image data separately so we can draw it later
        hookData.cell.dataImage = content;
        // @ts-expect-error contentImageHeight does not exist on type 'RawCell'
        hookData.cell.contentHeight = hookData.cell.raw?.contentImageHeight;
        // @ts-expect-error contentImageWidth does not exist on type 'RawCell'
        hookData.cell.contentWidth = hookData.cell.raw?.contentImageWidth;
        hookData.cell.text = [];
      }

      // @ts-expect-error Check if need to rotate the text
      if (hookData.cell.raw?.rotate) {
        // @ts-expect-error Storing rotated text and preventing it's default draw
        hookData.cell.textToRotate = hookData.cell.raw.content;
        hookData.cell.text = [];
      }
    },
    willDrawCell: options.willDrawCell,
    didDrawCell: function (hookData) {
      // @ts-expect-error Getting stored image content
      const content = hookData.cell.dataImage || hookData.cell.raw?.content;
      if (typeof content === "string" && content.startsWith("data:image")) {
        // @ts-expect-error Image height
        const imageHeight = hookData.cell.raw?.contentImageHeight;
        // @ts-expect-error Image width
        const imageWidth = hookData.cell.raw?.contentImageWidth;

        const imageXPosition = hookData.cell.x + (hookData.cell.width - imageWidth) / 2;
        const imageYPosition = hookData.cell.y + (hookData.cell.height - imageHeight) / 2;

        doc.addImage(content, imageXPosition, imageYPosition, imageWidth, imageHeight);
      }

      // @ts-expect-error Check if need to rotate the text
      if (hookData.cell.raw?.rotate) {
        // @ts-expect-error Read text content
        const content = hookData.cell.textToRotate || hookData.cell.raw?.content;

        const textXPosition = hookData.cell.x + hookData.cell.width / 2 + 4;
        const textYPosition = hookData.cell.y + hookData.cell.height - 8;

        doc.text(content, textXPosition, textYPosition, { angle: 90 });
      }

      if (options.didDrawCell) {
        options.didDrawCell(hookData);
      }
    },
  });
}

export function createTeamsTableRow(
  doc: jsPDF,
  options?: {
    startY?: number;
    teams?: { name?: string; logo_url?: string }[];
  },
) {
  const imageSize = 20; // Image dimensions 20x20
  const textImageGap = 8; // Gap between text and image
  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

  autoTable(doc, {
    head: [
      {
        team1: {
          content: options?.teams?.[0]?.name || "---",
          styles: {
            cellWidth: Number(options?.teams?.length) === 1 ? "auto" : (pageWidth - 89) / 2,
            cellPadding: {
              top: 12,
              bottom: 12,
              left: textImageGap + imageSize,
              right: textImageGap + imageSize,
            },
          },
        },
        ...(Number(options?.teams?.length) > 1 && {
          vs: {
            content: "vs",
            styles: {
              halign: "center",
              cellWidth: 49,
              fontSize: 12,
            },
          },
          team2: {
            content: options?.teams?.[1]?.name || "---",
            styles: {
              halign: "right",
              cellPadding: {
                top: 12,
                bottom: 12,
                left: textImageGap + imageSize,
                right: textImageGap + imageSize,
              },
            },
          },
        }),
      },
    ],
    headStyles: {
      fillColor: "#FFFFFF",
      lineColor: "#EEEEEE",
      lineWidth: {
        top: 1,
        bottom: 1,
      },
    },
    startY: options?.startY || 90,
    tableWidth: "auto",
    margin: {
      top: 0,
      bottom: 0,
      left: 20,
      right: 20,
    },
    styles: {
      cellPadding: {
        top: 12,
        bottom: 12,
        left: 0,
        right: 0,
      },
      font: "RedHatDisplay-Bold",
      fontStyle: "bold",
      fontSize: 10,
      textColor: "#1A1C1E",
      valign: "top",
      halign: "left",
    },
    didDrawCell: function (data) {
      const appEnv = useRuntimeConfig().public.appEnv;
      const isLocal = window.location.hostname === "localhost" || appEnv === "local";
      const defaultTeamLogo = "/images/no_team_logo.png";
      const baseImageUrlPrefix = isLocal ? "https://corsproxy.io/?" : "";

      if (data.column.index === 0) {
        let teamLogo = defaultTeamLogo;

        if (options?.teams?.[0].logo_url) teamLogo = baseImageUrlPrefix + options?.teams?.[0].logo_url;

        doc.addImage(teamLogo, "JPEG", data.cell.x, data.cell.y + 8, imageSize, imageSize);
      }
      else if (data.column.index === 2) {
        let teamLogo = defaultTeamLogo;

        if (options?.teams?.[1].logo_url) teamLogo = baseImageUrlPrefix + options?.teams?.[1].logo_url;

        doc.addImage(teamLogo, "JPEG", pageWidth - 40, data.cell.y + 8, imageSize, imageSize);
      }
    },
  });
}
