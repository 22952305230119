<script setup lang="ts">
import type { IEvent, ITeam } from "@/types";
import { BreakdownsModalSendBreakdown } from "#components";

const { t } = useI18n();
const props = defineProps<{
  event: IEvent;
  team: ITeam;
}>();
const emit = defineEmits(["breakdown-submit-success"]);
const modal = useModal();
const isBreakdownPurchasePopupShown = ref(false);
const isInsufficientBalancePopupShown = ref(false);

function onClick() {
  const teamBalance = props.team.basic_assigned + props.team.advanced_assigned;

  if (teamBalance > 0) {
    modal.open(BreakdownsModalSendBreakdown, {
      event: props.event,
      team: props.team,
      onInsufficientBalance,
      onBreakdownSubmitSuccess: () => emit("breakdown-submit-success"),
    });
  }
  else if (props.team.permissions.canPurchaseBreakdowns) {
    isBreakdownPurchasePopupShown.value = true;
  }
  else {
    isInsufficientBalancePopupShown.value = true;
  }
}

function onInsufficientBalance() {
  if (props.team.permissions.canPurchaseBreakdowns) {
    isBreakdownPurchasePopupShown.value = true;
  }
  else {
    isInsufficientBalancePopupShown.value = true;
  }
}
</script>

<template>
  <UBanner
    icon="analytics"
    class="mt-6"
  >
    <div class="flex flex-wrap items-center justify-between gap-4">
      <div class="flex flex-wrap gap-3 text-sm font-bold text-white">
        <span>
          {{ t("pages.breakdowns.send_breakdown.banner_title") }}
        </span>
        <a
          class="whitespace-nowrap text-sm font-medium underline"
          href="/"
          target="_blank"
        >
          {{ t("pages.breakdowns.send_breakdown.learn_more") }}
        </a>
      </div>
      <UButton
        class="font-bold"
        variant="light"
        :disabled="!props.team.permissions.canSendBreakdowns"
        :label="t('pages.breakdowns.send_breakdown.send_for_breakdown')"
        @click="onClick"
      />
    </div>
  </UBanner>
  <BreakdownsModalPurchase
    v-if="isBreakdownPurchasePopupShown"
    :title="t('pages.breakdowns.purchase_modal.title')"
    :description="t('pages.breakdowns.purchase_modal.description')"
    @close="isBreakdownPurchasePopupShown = false"
  />
  <BreakdownsModalInsufficientBalance
    v-if="isInsufficientBalancePopupShown"
    @close="isInsufficientBalancePopupShown = false"
  />
</template>
